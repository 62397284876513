<template>
  <div>
    <AuthProvider>
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
    </AuthProvider>
  </div>
</template>

<style>
html {
  font-family: 'GT Haptik', sans-serif;
  background-color: #fafafa;
}
</style>
