import { default as dashboardHdtnClvoGAMeta } from "/vercel/path0/pages/dashboard.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexAUNTNM8WtQMeta } from "/vercel/path0/pages/jobs/[job_id]/index.vue?macro=true";
import { default as new3p2ZcbwZvUMeta } from "/vercel/path0/pages/jobs/[job_id]/new.vue?macro=true";
import { default as mapFU58qNocjqMeta } from "/vercel/path0/pages/map.vue?macro=true";
import { default as profilekxSCn5bMpQMeta } from "/vercel/path0/pages/profile.vue?macro=true";
import { default as _91report_id_93qnueerAgONMeta } from "/vercel/path0/pages/reports/[report_id].vue?macro=true";
import { default as _91vehicle_id_93GC8IAXQ3eOMeta } from "/vercel/path0/pages/reports/damage/[vehicle_id].vue?macro=true";
import { default as indexGmBGSTDz3PMeta } from "/vercel/path0/pages/reports/index.vue?macro=true";
import { default as _91vehicle_id_93F17BZdrk3sMeta } from "/vercel/path0/pages/reports/missing/[vehicle_id].vue?macro=true";
import { default as _91vehicle_id_93tpEcMltxCwMeta } from "/vercel/path0/pages/reports/repair/[vehicle_id].vue?macro=true";
import { default as scanner04Q0auyfiUMeta } from "/vercel/path0/pages/scanner.vue?macro=true";
import { default as _91swap_id_93crHgOEaesKMeta } from "/vercel/path0/pages/swaps/[swap_id].vue?macro=true";
import { default as editQRAOFSACJwMeta } from "/vercel/path0/pages/users/[user_id]/edit.vue?macro=true";
import { default as index8PVvaQ47vOMeta } from "/vercel/path0/pages/users/[user_id]/index.vue?macro=true";
import { default as indexOXtObJcgq9Meta } from "/vercel/path0/pages/users/index.vue?macro=true";
import { default as newattJTZ643MMeta } from "/vercel/path0/pages/users/new.vue?macro=true";
import { default as indexu75nJm2xcsMeta } from "/vercel/path0/pages/vehicles/[vehicle_id]/index.vue?macro=true";
import { default as indexlCIuuyPO3CMeta } from "/vercel/path0/pages/vehicles/[vehicle_id]/jobs/index.vue?macro=true";
import { default as newx6TeN3Hdp3Meta } from "/vercel/path0/pages/vehicles/[vehicle_id]/jobs/new.vue?macro=true";
import { default as indexdkgr5gN20kMeta } from "/vercel/path0/pages/vehicles/index.vue?macro=true";
import { default as _91vehicle_id_93qagAfXjmHPMeta } from "/vercel/path0/pages/vehicles/swap/[vehicle_id].vue?macro=true";
export default [
  {
    name: dashboardHdtnClvoGAMeta?.name ?? "dashboard",
    path: dashboardHdtnClvoGAMeta?.path ?? "/dashboard",
    meta: dashboardHdtnClvoGAMeta || {},
    alias: dashboardHdtnClvoGAMeta?.alias || [],
    redirect: dashboardHdtnClvoGAMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexAUNTNM8WtQMeta?.name ?? "jobs-job_id",
    path: indexAUNTNM8WtQMeta?.path ?? "/jobs/:job_id()",
    meta: indexAUNTNM8WtQMeta || {},
    alias: indexAUNTNM8WtQMeta?.alias || [],
    redirect: indexAUNTNM8WtQMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/jobs/[job_id]/index.vue").then(m => m.default || m)
  },
  {
    name: new3p2ZcbwZvUMeta?.name ?? "jobs-job_id-new",
    path: new3p2ZcbwZvUMeta?.path ?? "/jobs/:job_id()/new",
    meta: new3p2ZcbwZvUMeta || {},
    alias: new3p2ZcbwZvUMeta?.alias || [],
    redirect: new3p2ZcbwZvUMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/jobs/[job_id]/new.vue").then(m => m.default || m)
  },
  {
    name: mapFU58qNocjqMeta?.name ?? "map",
    path: mapFU58qNocjqMeta?.path ?? "/map",
    meta: mapFU58qNocjqMeta || {},
    alias: mapFU58qNocjqMeta?.alias || [],
    redirect: mapFU58qNocjqMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/map.vue").then(m => m.default || m)
  },
  {
    name: profilekxSCn5bMpQMeta?.name ?? "profile",
    path: profilekxSCn5bMpQMeta?.path ?? "/profile",
    meta: profilekxSCn5bMpQMeta || {},
    alias: profilekxSCn5bMpQMeta?.alias || [],
    redirect: profilekxSCn5bMpQMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: _91report_id_93qnueerAgONMeta?.name ?? "reports-report_id",
    path: _91report_id_93qnueerAgONMeta?.path ?? "/reports/:report_id()",
    meta: _91report_id_93qnueerAgONMeta || {},
    alias: _91report_id_93qnueerAgONMeta?.alias || [],
    redirect: _91report_id_93qnueerAgONMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/reports/[report_id].vue").then(m => m.default || m)
  },
  {
    name: _91vehicle_id_93GC8IAXQ3eOMeta?.name ?? "reports-damage-vehicle_id",
    path: _91vehicle_id_93GC8IAXQ3eOMeta?.path ?? "/reports/damage/:vehicle_id()",
    meta: _91vehicle_id_93GC8IAXQ3eOMeta || {},
    alias: _91vehicle_id_93GC8IAXQ3eOMeta?.alias || [],
    redirect: _91vehicle_id_93GC8IAXQ3eOMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/reports/damage/[vehicle_id].vue").then(m => m.default || m)
  },
  {
    name: indexGmBGSTDz3PMeta?.name ?? "reports",
    path: indexGmBGSTDz3PMeta?.path ?? "/reports",
    meta: indexGmBGSTDz3PMeta || {},
    alias: indexGmBGSTDz3PMeta?.alias || [],
    redirect: indexGmBGSTDz3PMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/reports/index.vue").then(m => m.default || m)
  },
  {
    name: _91vehicle_id_93F17BZdrk3sMeta?.name ?? "reports-missing-vehicle_id",
    path: _91vehicle_id_93F17BZdrk3sMeta?.path ?? "/reports/missing/:vehicle_id()",
    meta: _91vehicle_id_93F17BZdrk3sMeta || {},
    alias: _91vehicle_id_93F17BZdrk3sMeta?.alias || [],
    redirect: _91vehicle_id_93F17BZdrk3sMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/reports/missing/[vehicle_id].vue").then(m => m.default || m)
  },
  {
    name: _91vehicle_id_93tpEcMltxCwMeta?.name ?? "reports-repair-vehicle_id",
    path: _91vehicle_id_93tpEcMltxCwMeta?.path ?? "/reports/repair/:vehicle_id()",
    meta: _91vehicle_id_93tpEcMltxCwMeta || {},
    alias: _91vehicle_id_93tpEcMltxCwMeta?.alias || [],
    redirect: _91vehicle_id_93tpEcMltxCwMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/reports/repair/[vehicle_id].vue").then(m => m.default || m)
  },
  {
    name: scanner04Q0auyfiUMeta?.name ?? "scanner",
    path: scanner04Q0auyfiUMeta?.path ?? "/scanner",
    meta: scanner04Q0auyfiUMeta || {},
    alias: scanner04Q0auyfiUMeta?.alias || [],
    redirect: scanner04Q0auyfiUMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/scanner.vue").then(m => m.default || m)
  },
  {
    name: _91swap_id_93crHgOEaesKMeta?.name ?? "swaps-swap_id",
    path: _91swap_id_93crHgOEaesKMeta?.path ?? "/swaps/:swap_id()",
    meta: _91swap_id_93crHgOEaesKMeta || {},
    alias: _91swap_id_93crHgOEaesKMeta?.alias || [],
    redirect: _91swap_id_93crHgOEaesKMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/swaps/[swap_id].vue").then(m => m.default || m)
  },
  {
    name: editQRAOFSACJwMeta?.name ?? "users-user_id-edit",
    path: editQRAOFSACJwMeta?.path ?? "/users/:user_id()/edit",
    meta: editQRAOFSACJwMeta || {},
    alias: editQRAOFSACJwMeta?.alias || [],
    redirect: editQRAOFSACJwMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/users/[user_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: index8PVvaQ47vOMeta?.name ?? "users-user_id",
    path: index8PVvaQ47vOMeta?.path ?? "/users/:user_id()",
    meta: index8PVvaQ47vOMeta || {},
    alias: index8PVvaQ47vOMeta?.alias || [],
    redirect: index8PVvaQ47vOMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/users/[user_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOXtObJcgq9Meta?.name ?? "users",
    path: indexOXtObJcgq9Meta?.path ?? "/users",
    meta: indexOXtObJcgq9Meta || {},
    alias: indexOXtObJcgq9Meta?.alias || [],
    redirect: indexOXtObJcgq9Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: newattJTZ643MMeta?.name ?? "users-new",
    path: newattJTZ643MMeta?.path ?? "/users/new",
    meta: newattJTZ643MMeta || {},
    alias: newattJTZ643MMeta?.alias || [],
    redirect: newattJTZ643MMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/users/new.vue").then(m => m.default || m)
  },
  {
    name: indexu75nJm2xcsMeta?.name ?? "vehicles-vehicle_id",
    path: indexu75nJm2xcsMeta?.path ?? "/vehicles/:vehicle_id()",
    meta: indexu75nJm2xcsMeta || {},
    alias: indexu75nJm2xcsMeta?.alias || [],
    redirect: indexu75nJm2xcsMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/vehicles/[vehicle_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexlCIuuyPO3CMeta?.name ?? "vehicles-vehicle_id-jobs",
    path: indexlCIuuyPO3CMeta?.path ?? "/vehicles/:vehicle_id()/jobs",
    meta: indexlCIuuyPO3CMeta || {},
    alias: indexlCIuuyPO3CMeta?.alias || [],
    redirect: indexlCIuuyPO3CMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/vehicles/[vehicle_id]/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: newx6TeN3Hdp3Meta?.name ?? "vehicles-vehicle_id-jobs-new",
    path: newx6TeN3Hdp3Meta?.path ?? "/vehicles/:vehicle_id()/jobs/new",
    meta: newx6TeN3Hdp3Meta || {},
    alias: newx6TeN3Hdp3Meta?.alias || [],
    redirect: newx6TeN3Hdp3Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/vehicles/[vehicle_id]/jobs/new.vue").then(m => m.default || m)
  },
  {
    name: indexdkgr5gN20kMeta?.name ?? "vehicles",
    path: indexdkgr5gN20kMeta?.path ?? "/vehicles",
    meta: indexdkgr5gN20kMeta || {},
    alias: indexdkgr5gN20kMeta?.alias || [],
    redirect: indexdkgr5gN20kMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/vehicles/index.vue").then(m => m.default || m)
  },
  {
    name: _91vehicle_id_93qagAfXjmHPMeta?.name ?? "vehicles-swap-vehicle_id",
    path: _91vehicle_id_93qagAfXjmHPMeta?.path ?? "/vehicles/swap/:vehicle_id()",
    meta: _91vehicle_id_93qagAfXjmHPMeta || {},
    alias: _91vehicle_id_93qagAfXjmHPMeta?.alias || [],
    redirect: _91vehicle_id_93qagAfXjmHPMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/vehicles/swap/[vehicle_id].vue").then(m => m.default || m)
  }
]